export const PUBLIC_ROUTE_TNDS = {
  MOTORBIKE: "/grab/tnds-xemay",
  MOTORBIKE_PVI: "/grab/pvi/tnds-xemay",
  MOTORBIKE_PVI_V2: "/grab/driver/pvi/tnds-xemay",
  MOTORBIKE_PTI: "/grab/pti/tnds-xemay",
  CAR: "/grab/tnds-oto",
  CAR_PVI: "/grab/pvi/tnds-oto",
  CAR_VNI: "/grab/vni/tnds-oto",
  CAR_PVI_V2: "/grab/driver/pvi/tnds-oto",
  CAR_PTI: "/grab/pti/tnds-oto",
  // VACCINE: "/grab/phong-ve",
  GIFT_VUNGTAM: "/grab/gift/vung-tam",
  VUNGTAM: "/grab/vung-tam",
  VUNGTAM_PAYMENT_RESULT: "/grab/vung-tam/loading",
  VUNGTAMAN: "/grab/vung-tam-3in1",
  VUNGTAMAN_PAYMENT_RESULT: "/grab/vung-tam-3in1/loading",
  CAR_PTI_TNDS_VA_VCX: "/grab/tnds-vat-chat-oto",
  CAR_PTI_TNDS_VA_VCX_RESULT: "/grab/tnds-vcx-oto/result",
  CAR_MIC_VCX: "/grab/vni-mic/tnds-vcx-oto",
  CAR_MIC_VCX_RESULT: "/grab/vni-mic/tnds-vcx-oto/result",
};

export const PUBLIC_ROUTE_DOTQUY = {
  BHDOTQUY: "/dot-quy",
  PAYMENT_RESULT: "/dot-quy/loading",
};

export const PUBLIC_ROUTE_PTI_TNDSXEMAY = {
  MAIN: "/pti/tnds-xemay",
  PAYMENT: "/pti/xemay-payment",
  PAYMENT_RESULT: "/pti/tnds-xemay/loading",
};

export const PUBLIC_ROUTE_PTI_TNDSOTO = {
  MAIN: "/pti/tnds-oto",
  PAYMENT: "/pti/oto-payment",
  PAYMENT_RESULT: "/pti/tnds-oto/loading",
};

export const PUBLIC_ROUTE_PTI_VATCHATXEMAY = {
  MAIN: "/pti/vatchatxemay",
  PAYMENT: "/pti/vatchatxemay-payment",
  PAYMENT_RESULT: "/pti/vatchatxemay/loading",
};

export const PUBLIC_ROUTE_PTI_BENH_HIEM_NGHEO = {
  MAIN: "/pti/benh-hiem-ngheo",
};

export const PUBLIC_ROUTE_PTI_VUNG_TAM_AN = {
  MAIN: "/pti/vung-tam-an",
};

export const PUBLIC_ROUTE_PTI_BHSK = {
  MAIN: "/pti/bhsk",
};

export const PUBLIC_ROUTE_LIAN_BHTRUCOT = {
  MAIN: "/lian/bao-hiem-tru-cot",
};

export const PUBLIC_ROUTE_PVI_HOME_CARE = {
  MAIN: "/pvi/home-care",
};
export const PUBLIC_ROUTE_PVI_TRU_COT = {
  MAIN: "/express/pvi/bao-hiem-tru-cot",
};

export const PUBLIC_ROUTE_PAGE_ALL_PRODUCTS = {
  MAIN: "/all-products",
};

export const PUBLIC_ROUTE_PTI_FINA_BHSUCKHOE = {
  MAIN: "/pti/bao-hiem-suc-khoe",
};

export const PUBLIC_ROUTE_PVI_TRO_CAP_NAM_VIEN = {
  MAIN: "/pvi/tro-cap-nam-vien",
};
export const PUBLIC_ROUTE_PVI_TAI_NAN_CA_NHAN = {
  MAIN: "/pvi/bao-hiem-tai-nan",
  PAYMENT_RESULT: "/pvi/bao-hiem-tai-nan/loading",
};

export const PUBLIC_ROUTE_MIC_TNDSXEMAY = {
  MAIN: "/mic/tnds-xemay",
  PAYMENT: "/mic/xemay-payment",
  LOADING_PAYMENT: "/mic/tnds-xemay/loading",
};

export const PUBLIC_ROUTE_TNDSXEMAY = {
  MAIN: "/tnds-xemay",
  PAYMENT: "/xemay-payment",
};

export const PUBLIC_ROUTE_MIC_TNDS_VCX_CAR = {
  MAIN: "/mic/tnds-vat-chat-oto",
};

export const PUBLIC_ROUTE_TNDS_VCX_CAR = {
  MAIN: "/tnds-vat-chat-oto",
};

export const PUBLIC_ROUTE_SALE_TNDS_VCX_CAR = {
  MAIN: "/campaign/tnds-oto",
};

export const PUBLIC_ROUTE_SALE_TNDS_VCX_CAR_RESULT = {
  MAIN: "/campaign/tnds-oto/result",
};

export const RETRACK_LOADING = {
  MAIN: "/rentrack/loading",
};

export const PUBLIC_ROUTE_SELLY_MIC_TNDSXEMAY = {
  MAIN: "/selly/tnds-xemay",
};

export const PUBLIC_ROUTE_SELLY_MIC_TNDSXEMAY_ALL = {
  MAIN: "/selly/admin/tnds-xemay",
};

export const PUBLIC_ROUTE_MB_BANK_DOWNNLOAD_APP = {
  MAIN: "/mbbank/pti/gift",
};

export const PUBLIC_ROUTE_GRAB_PTI_VUNG_TAM_AN = {
  MAIN: "/grab/vung-tam-3in1",
};

export const PUBLIC_ROUTE_MIC_BAO_AN_TINH_DUNG = {
  MAIN: "/mic/bao-an-tin-dung",
};

export const PUBLIC_ROUTE_REGIST_ACC_GSALE = {
  MAIN: "/gsale/reg",
};

export const PUBLIC_ROUTE_RENEWALS_ORDER_INFO = {
  MAIN: "/renewals/order-info",
};

export const PUBLIC_ROUTE_RENEWALS_ORDER_RESULT = {
  MAIN: "/renewals/order-result",
};

export const PUBLIC_ROUTE_REBUILD_WEB_BYPRODUCTID = {
  MAIN: "/build-url-by-merchant",
};

export const PUBLIC_ROUTE_PTI_TNDS_OTOV2 = {
  MAIN: "/v2/tnds-oto",
};

export const PUBLIC_ROUTE_TOMATO_COMBO = {
  MAIN: "/tomato-combo",
  PAYMENT_DONE: "/tomato-combo/loading",
  ACTIVE: "/tomato-combo/active",
  ACTIVE_RESULT: "/tomato-combo/active/result",
};

export const LANDING_PAGE_TOMATO = {
  MAIN: "/langding-page-tomato",
};

export const PUBLIC_ROUTE_EASYCARE = {
  MAIN: "/lian/easy-care",
};

export const PUBLIC_ROUTE_PHUCBINHAN = {
  MAIN: "/pvi/phuc-binh-an",
};

export const PUBLIC_ROUTE_PTI_EPASS_TNDSOTO = {
  MAIN: "/pti/epass/tnds-oto",
  PAYMENT: "/pti/oto-payment",
  PAYMENT_RESULT: "/pti/tnds-oto/loading",
};

export const PUBLIC_ROUTE_VNI_EPASS_TNDSOTO = {
  MAIN: "/vni/epass/tnds-oto",
  PAYMENT: "/vni/oto-payment",
  PAYMENT_RESULT: "/vni/tnds-oto/loading",
};

export const PUBLIC_ROUTE_VNI_TNDSOTO = {
  MAIN: "/vni/tnds-oto",
};

export const PUBLIC_ROUTE_PAGE_ALL_PRODUCTS_EPASS = {
  MAIN: "/all-products-epass",
};

export const PUBLIC_ROUTE_PAGE_TRAVEL = {
  MAIN: "/travel",
  GROUP: "/group-travel",
};

export const PUBLIC_ROUTE_PAGE_FAMILY_CARE = {
  MAIN: "/lian/family-care",
};

export const PUBLIC_ROUTE_PVI_BENHHIEMNGHEO = {
  MAIN: "/pvi/benh-hiem-ngheo",
};

export const PUBLIC_ROUTE_TOMATO_GSALE = {
  MAIN: "/tomato/bao-hiem-tomato",
};

export const PUBLIC_ROUTE_MIC_BUSINESS_TRAVEL = {
  MAIN: "/mic/du-lich-doanh-nghiep",
};

export const PUBLIC_ROUTE_BAO_HIEM_Y_TE = {
  MAIN: "/bao-hiem-y-te",
};

export const PUBLIC_ROUTE_LUCKY_SPIN = {
  MAIN: "/lucky_spin",
  GC: "/team-building",
};

export const PUBLIC_ROUTE_LALAMOVE_V2_TNDS_XEMAY = {
  MAIN: "/lalamove/v2/tnds-xemay",
};

export const PUBLIC_ROUTE_LALAMOVE_V2_TNDS_OTO = {
  MAIN: "/lalamove/v2/tnds-oto",
};

export const PUBLIC_ROUTE_EPASS_V2_TNDS_XEMAY = {
  MAIN: "/epass/v2/tnds-xemay",
};

export const PUBLIC_ROUTE_EPASS_V2_TNDS_OTO = {
  MAIN: "/epass/v2/tnds-oto",
};

export const PUBLIC_ROUTE_LALAMOVE_PTI_VATCHATXEMAY = {
  MAIN: "/lalamove/pti/vatchatxemay",
};

export const PUBLIC_ROUTE_BM_CARE = {
  MAIN: "/bm-care",
};

export const PUBLIC_ROUTE_MASTER_RIDER = {
  MAIN: "/master-rider",
};

export const PUBLIC_ROUTE_REFUND_COVER = {
  MAIN: "/bao-hiem-refund-cover",
};

export const PUBLIC_ROUTE_V2_BAO_HIEM_Y_TE = {
  MAIN: "/v2/bao-hiem-y-te",
};

export const PUBLIC_ROUTE_VNI_VCX_CAR = {
  MAIN: "/vni/vcx-oto",
};

export const PUBLIC_ROUTE_VNI_VCX_EPASS = {
  MAIN: "/epass/vcx-oto",
};

export const PUBLIC_ROUTE_BMI_VCX_CAR = {
  // MAIN: "/bmi/vcx-oto",
  MAIN: "/bao-minh/vcx-oto",
};

export const PUBLIC_ROUTE_PAGE_MERCHANT_HUB = {
  MAIN: "/merchant-hub",
};

export const PUBLIC_ROUTE_SUC_KHOE_VANG = {
  MAIN: "/suc-khoe-vang",
};

export const PUBLIC_ROUTE_GS_WHEEL = {
  MAIN: "/gsale-wheel",
};
